<template>
	<div class='container' v-if="show">
		<div class="discount-page">
			<div class="discount">
				<div class="ss" >
					<gold :data="coupon" :isRight="isUse" :opacity="isUse" :state="isUse&&isUse2 ? 4 : 0"/>
				</div>
				<van-button  block class="btn" @click="btns">{{isUse ? '立即使用' :'立即领取'}}</van-button>
			</div>

		</div>
		<div class="list-title">推荐商品</div>
		<goods-list :data="goods" @click="clickDetail" />
	</div>
	<van-empty description="暂无信息" v-else/>
</template>

<script>
	import GoodsList from "@/components/GoodsList";
	import gold from "@/components/Coupon/Coupon";
	export default {
		// Vue 实例的数据对象
		name: "go",
		data() {
			return {
				goods: [],
				coupon:{},
				isUse:false,
				isUse2:true,
				show:false,
			}
		},
		components: {
			GoodsList,
			gold
		},
		created() {
			this.getData()
		},
		methods: {
			async getData() {
				let query = this.$route.query
				if(query.sign){
					const res = await axios.get("/get_coupon_info", {
						params: {
							coupon_id: query.id,
							sign:query.sign
						}
					});
					if(res.code == 1){
						this.goods = res.data.goods
						this.coupon = res.data.coupon
						 this.show = true
					}else{
						this.$toast(res.message)
					}
				}
			},
			btns() {
				if(!this.isUse){
					let id = this.$route.query.id
					axios.get(
						"recive_coupon",{
							params: {
								coupon_id: id
							}
						}
					).then(res => {
						if(res.code == 1){
							this.$toast('领取成功')
						}
						else{
							this.$toast(res.message)
							this.isUse2 = false
						}
						this.isUse = true
						
					})
				}else{
					this.$router.push({path:'/'})
				}
			},
			clickDetail(data) {
			    this.$router.push("/detail/" + data.id);
			},
		}
	}
</script>

<style lang='less' scoped>
	.container {
		padding-bottom: 20px;

		.discount-page {
			background-color: #F6F6F6;
			padding-top: 15px;
			padding-bottom: 15px;

			.discount {
				padding: 15px;
				box-sizing: border-box;

				.ss {
					margin-bottom: 20px;
				}
			}
		}

		.list-title {
			margin-top: 10px;
			margin-bottom: 10px;
			font-size: 18px;
			font-weight: bold;
			text-align: center;
		}
	}

	.btn {
		width: 130px;
		margin: auto;
		background: linear-gradient(270deg, #00B792 0%, #00C795 100%);
		border-radius: 5px;
		font-size: 15px;
		color: #fff;
	}
</style>
